
import GwDefaultTemplate from '~/patterns/templates/default.vue';
import GwHero from '~/patterns/molecules/hero/presets/hero-content.vue';

export default {
    components: {
        GwDefaultTemplate,
        GwHero,
    },
    props: {
        hero: {
            type: Array,
            default: null
        },
        darkMode: {
            type: Boolean,
            default: false
        },
        wide: {
            type: Boolean,
            default: false
        },
        loading: {
            type: Boolean,
            default: false
        }
    }
};
